import PropTypes from 'prop-types';

import CoreShapka from 'core/components/Shapka';
import Link from 'core/components/Link';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import ShareButton from './share.svg';

import styles from './index.styl';


function Shapka({ isMobile, isSecondShapkaForPremium }) {
  const promoAttributes = {
    width: isMobile ? 90 : 140,
    height: isMobile ? 40 : 50,
  };

  return (
    <CoreShapka
      promo={(
        <Link
          className={styles.link}
          to='https://www.passion.ru/news/eksklyuzivy/predlozhite-novost-10-10-2023.htm'
        >
          <ShareButton {...promoAttributes} />
        </Link>
      )}
      isSecondShapkaForPremium={isSecondShapkaForPremium}
      fullwidth={isSecondShapkaForPremium}
    />
  );
}

Shapka.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  isSecondShapkaForPremium: PropTypes.bool,
};

const ShapkaWithHOCs = withBreakpoint(Shapka);
ShapkaWithHOCs.displayName = 'Shapka';

export default ShapkaWithHOCs;

export { Shapka as StorybookComponent };
