import PropTypes from 'prop-types';

import Drum from 'core/components/Drum';

import readMore from 'core/components/TopicContent/blocks/readMore';

import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import Card2 from 'site/cards/Card2';
import { Card3Type5, Card3Type6 } from 'site/cards/Card3';

import styles from './index.styl';


export default function ReadMore(props) {
  const {
    block,
    parentProps: {
      theme,
      isMobile,
    },
  } = props;

  const {
    attributes: {
      topic_ids: ids,
      content,
    },
  } = block || { attributes: [] };

  if (!ids.length) return null;

  const breakpointCard = isMobile ? Card3Type5 : Card3Type6;

  if (ids.length > 2) {
    return (
      <div className={styles.readMore}>
        <style jsx>{`
          .${styles.readMore}
            border-top 2px solid ${theme.colors.active1}
            :global(.mobile) &
              border-top none
          .${styles.title}
            font bold italic 22px/1.2 ${theme.fonts.display}
            color ${theme.colors.active1}
            :global(.mobile) &
              font-size 20px
        `}</style>
        <div className={styles.title}>
          Читайте также
        </div>
        <Drum
          ids={ids}
          card={Card2}
          arrowsShift={-10}
          arrowsShiftVertical={80}
          itemWidth='275px'
          itemWidthMobile='80%'
          content={content}
        />
      </div>
    );
  }

  return readMore(
    block,
    {
      title: isMobile ? 'Читайте на тему:' : 'Читайте также',
      singleCard: breakpointCard,
      feedCard: breakpointCard,
    }
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  parentProps: PropTypes.object,
};
