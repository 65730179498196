import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

export const HOST = 'www.passion.ru';

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig(requestHeaders) {
  const hostName = requestHeaders
    ? resolve({
      'dev': HOST,
      '*': requestHeaders.host,
    }) : HOST;

  const siteUrl = 'https://' + hostName;

  return {
    host: hostName,
    siteName: 'passion',
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },
    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//stage1.passion.ru/api/v2',
      }),
    },
    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//stage1.passion.ru',
    }),
    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),
    erratumUrl: `${siteUrl}/erratum`,

    schemaOrg: {
      organizationName: 'Страсти',
      alternateName: 'Passion',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 245,
      organizationLogoHeight: 60,
    },

    isUnityProject: true,

    defaultSiteMeta: {
      titleTemplate: '%s - Страсти',
      defaultTitle: 'Страсти — новости шоу бизнеса и реальные истории из жизни',
      meta: [
        { name: 'description', content: 'Новости, которые заводят' },
      ],
    },

    ramblerCommentsAppId: resolve({
      'production': 141,
      '*': 189,
    }),

    ads: {
      siteId: '432500798',
      antiadblock: true,
    },

    counterIds: {
      googleAnalytics: 'UA-2464892-5',
      ga4: 'G-1GCH5S687P',
      liveInternet: { ids: ['Passion', 'Lenta', 'gazeta_all'] },
      tns: { account: 'passion_ru', tmsec: 'passion_total' },
      mediascope: 'rambler_cid1100108-posid2155109/',
      top100: 3149592,
      yandexMetrika: 7399405,
      mailru: 3090260,
      rcm: resolve({
        '*': 'RCM-D46C',
        'production': 'RCM-5A02',
      }),
      loyalty: true,
    },

    pushWoosh: {
      applicationCode: resolve({
        '*': '55DB1-775EA',
        'staging': '72ACF-82356',
      }),
      safariWebsitePushID: 'web.rambler.passion',
      defaultNotificationTitle: 'Passion.ru',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    features: {
      enableRetina: true,
      forcedDesktopFromZen: false,
      disableBlurPreview: true,
      useSmartResizeForSocialImage: true,
      isNewVersionPremiumTopic: true,
      enableFenwick: true,
    },

    specialLinks: [
      '/hor/goroskop-na-god/goroskop-na-2019-god.htm',
      '/hor/goroskop-na-god/goroskop-na-2020-god-kakie-izmeneniya-proizoidut-v-karere-i-lichnoi-zhizni-u-raznykh-znakov-zodiaka.htm',
      '/hor/goroskop-na-god/goroskop-na-2021-god-kakie-izmeneniya-v-lichnoi-zhizni-i-karere-vas-ozhidayut-soglasno-znaku-zodiaka.htm',
      '/psy/v-ladu-s-soboy/kak-vospitat-v-rebenke-privychku-pomogat-7-sovetov.htm',
      '/psy/v-ladu-s-soboy/pochemu-ne-nuzhno-boyatsya-pomogat-drugim.htm',
      '/psy/v-ladu-s-soboy/masshtab-vo-blago-kak-bolshoi-biznes-pomogaet-lyudyam.htm',
      '/psy/v-ladu-s-soboy/7-glavnykh-faktov-o-blagotvoritelnosti-v-rossii.htm',
    ],

    spammer: {
      ramblerCrmProjectKey: 'passion_ru',
      termsUrl: 'https://help.rambler.ru/legal/1706/',
      digestKey: 'product',
    },

    ramblerId: {
      rname: 'passion',
      useAuthRedirect: true,
    },

    socials: [
      { name: 'Vkontakte', link: 'https://vk.com/passionru' },
      { name: 'Odnoklassniki', link: 'https://ok.ru/group/54550166110227' },
      { name: 'Youtube', link: 'https://www.youtube.com/channel/UCr5n7DHCQxSAryydFykWmqw' },
      { name: 'Yandex_Dzen', link: 'https://dzen.ru/passion?favid=1670' },
    ],
  };
}
