import PropTypes from 'prop-types';

import SearchPage from 'core/components/SearchPage';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecSearchNative,
} from 'site/components/Ads/mobile';

import CardSearch from 'site/cards/CardSearch';

import filters from './filters';

import ColumnLayout from 'core/components/ColumnLayout';

import InnerSideColumn from 'site/components/InnerSideColumn';


function Search({ rawContent, breakpoint }) {
  return (
    <ColumnLayout
      rightColumn={(
        <InnerSideColumn
          showBoesque={false}
          showSmi24Vertical={false}
        />
      )}
    >
      <style jsx>{`
        .mobile
          padding 0 20px
      `}
      </style>
      <div className={breakpoint}>
        <SearchPage
          rawContent={rawContent}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={ListingSpecSearchNative}
          card={CardSearch}
        />
      </div>
    </ColumnLayout>
  );
}


Search.propTypes = {
  rawContent: PropTypes.array,
  breakpoint: breakpointPropTypes(),
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: CardSearch }),
});

export default withPageHocs(dataProvider)(withBreakpoint(Search));
