import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import withTheme from 'core/components/theme';

import styles from './index.styl';

function Title2(props) {
  const {
    children,
    theme,
    className,
  } = props;

  return (
    <div className={cx(styles.title, className)}>
      <style jsx>{`
        .${styles.title}
          font 500 20px/25px ${theme.fonts.display}
          color ${theme.colors.black}
      `}
      </style>
      {children}
    </div>
  );
}

Title2.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  breakpoint: breakpointPropTypes(),
  className: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(Title2);
