import PropTypes from 'prop-types';

import { withBreakpoint, Desktop } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import TopicFooter from 'core/components/TopicFooter';
import withTheme from 'core/components/theme';

import withTopic from 'core/components/Topic/withTopic';
import { checkScrollToSiteInTopic } from 'core/components/ScrollToSite';

import { PartnetsHorizontal } from 'site/components/Partners';
import { IndentWrap } from 'site/components/Wrappers';
import Sponsored from 'site/components/Ads/Sponsored';
import Recommender from 'site/components/Recommender';
import Hr from 'site/components/Hr';

import {
  SuperFooter,
} from 'site/components/Ads/desktop';


function TopicFooterWrapper(props) {
  const {
    isDesktop,
    topic,
    theme: {
      controls: {
        topicFooter: {
          block: {
            spacing,
          },
        },
      },
    },
  } = props;

  const {
    content,
    infinityIndex,
  } = topic || { content: props.content };

  const {
    is_premium: isPremiumTopic,
  } = content?.attributes || {};

  const isScrollToSite = topic && checkScrollToSiteInTopic(topic);

  const sideSpacings = {
    right: isDesktop ? 0 : spacing,
    left: isDesktop ? 0 : spacing,
  };

  return (
    <IndentWrap bottom={spacing}>
      {!isPremiumTopic && (
        <>
          <Sponsored />
          <Hr indentTop={30} indent={0} />
          <TopicFooter
            content={content}
            hrefBuilder={slug => `/label/${slug}`}
          />
        </>
      )}
      {!isScrollToSite && (
        <>
          {!isPremiumTopic &&
            <Desktop>
              {/* TODO: Если отступ задать нижний, то сайт может падать
              при клиентской навигации. Кажется это происходит только локально
              и когда LB прилинкован. В любом случае лучше выяснить что не так.
              */}
              <AdWrapper top={40}>
                <SuperFooter />
              </AdWrapper>
            </Desktop>
          }
          <IndentWrap top={spacing}>
            {infinityIndex % 2 !== 0
              ? <IndentWrap {...sideSpacings}><Recommender /></IndentWrap>
              : <PartnetsHorizontal />
            }
          </IndentWrap>
        </>
      )}
    </IndentWrap>
  );
}

TopicFooterWrapper.propTypes = {
  content: PropTypes.object,
  topic: PropTypes.shape({
    content: PropTypes.object,
    infinityIndex: PropTypes.number,
  }),
  isDesktop: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

export default withBreakpoint(withTopic(withTheme(TopicFooterWrapper)));
